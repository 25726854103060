<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" lg="4" md="4" sm="12" xs="12">
        <v-card class="mx-auto rounded-lg" outlined>
          <v-list-item three-line>
            <v-list-item-content>
              <div class="mb-4">
                <h5 color="black--text">TOTAL POBLACION CENSADA</h5>
              </div>
              <v-list-item-title class="text-h5 mb-1" color="primary">
                <h6 color="black--text">
                  {{ cardValues.total }}
                </h6>
              </v-list-item-title>
              <v-list-item-subtitle></v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-avatar
              tile
              size="50"
              color="#C1ECCB"
              class="rounded-circle"
            >
              <v-icon size="35">mdi-account-group</v-icon>
            </v-list-item-avatar>
          </v-list-item>
          <!-- <v-card-actions>
            <h6>
              <span class="primary--text">
                <v-icon size="12" color="primary">mdi-plus</v-icon>
                3.78%
              </span>
              desde el mes pasado
            </h6>
          </v-card-actions> -->
        </v-card>
      </v-col>
      <v-col cols="12" lg="4" md="4" sm="12" xs="12">
        <v-card class="mx-auto" outlined>
          <v-list-item three-line>
            <v-list-item-content>
              <div class="mb-4">
                <h5 color="black--text">TOTAL MUJERES CENSADAS</h5>
              </div>
              <v-list-item-title class="text-h5 mb-1" color="primary">
                <h6 color="black--text">{{ cardValues.women }}</h6>
              </v-list-item-title>
              <v-list-item-subtitle></v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-avatar
              tile
              size="50"
              color="#C1ECCB"
              class="rounded-circle"
            >
              <v-icon size="35">mdi-human-female</v-icon>
            </v-list-item-avatar>
          </v-list-item>
          <!-- <v-card-actions>
            <h6>
              <span class="primary--text">
                <v-icon size="12" color="primary">mdi-plus</v-icon>
                3.78%
              </span>
              desde el mes pasado
            </h6>
          </v-card-actions> -->
        </v-card>
      </v-col>
      <v-col cols="12" lg="4" md="4" sm="12" xs="12">
        <v-card class="mx-auto" outlined>
          <v-list-item three-line>
            <v-list-item-content>
              <div class="mb-4">
                <h5 color="black--text">TOTAL HOMBRES CENSADOS</h5>
              </div>
              <v-list-item-title class="text-h5 mb-1" color="primary">
                <h6 color="black--text">{{ cardValues.men }}</h6>
              </v-list-item-title>
              <v-list-item-subtitle></v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-avatar
              tile
              size="50"
              color="#C1ECCB"
              class="rounded-circle"
            >
              <v-icon size="35">mdi-human-male</v-icon>
            </v-list-item-avatar>
          </v-list-item>
          <!-- <v-card-actions>
            <h6>
              <span class="primary--text">
                <v-icon size="12" color="primary">mdi-plus</v-icon>
                3.78%
              </span>
              desde el mes pasado
            </h6>
          </v-card-actions> -->
        </v-card>
      </v-col>
    </v-row>
    <!-- <v-row class="mt-2">
      <v-col md="6" lg="6" sm="12" xs="12">
        <v-card>
          <v-card-text class="">
            <h3
              class="title blue-grey--text text--darken-2 font-weight-regular"
            >
              POBLACION POR GENERO
            </h3>
            <h6 class="subtitle-2 font-weight-light"></h6>
            <div class="" max-height="100">
              <graph-by-gender :censusStats="censusStats"></graph-by-gender>
            </div>
          </v-card-text>
        </v-card>

        <v-card class="mt-4">
          <v-card-text class="pa-5">
            <h3
              class="title blue-grey--text text--darken-2 font-weight-regular"
            >
              POBLACION POR NIVEL DE ESCOLARIDAD
            </h3>
            <h6 class="subtitle-2 font-weight-light"></h6>
            <div class="pb-6">
              <education-level-graph
                :censusStats="censusStats"
              ></education-level-graph>
            </div>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" md="6" lg="6" sm="12" xs="12">
        <v-card>
          <v-card-text class="pa-5">
            <h3
              class="title blue-grey--text text--darken-2 font-weight-regular"
            >
              POBLACION POR EDAD
            </h3>
            <h6 class="subtitle-2 font-weight-light"></h6>
            <div class="pb-6">
              <graph-by-age :censusStats="censusStats"></graph-by-age>
            </div>
          </v-card-text>
        </v-card>

        <v-card class="mt-4">
          <v-card-text>
            <h3
              class="title blue-grey--text text--darken-2 font-weight-regular"
            >
              TENDENCIA DE VIVIENDAS
            </h3>
            <h6 class="subtitle-2 font-weight-light"></h6>
            <div class="pb-6">
              <graph-housing-trend
                :censusStats="censusStats"
              ></graph-housing-trend>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row> -->
  </v-container>
</template>

<script>
import { infoCards } from '@/services/censusService'
import sweetalert from "sweetalert2";

export default {

  data() {
    return {
      censusStats: [],
      cardValues: {
        total: 0,
        women: 0,
        men: 0,
      }
    };
  },
  async created() {
    try {
      this.showLoader();
      const { data: { total_comunero = 0, total_comunero_hombre = 0, total_comunero_mujeres = 0 } } = await infoCards();
      this.cardValues.total = total_comunero;
      this.cardValues.women = total_comunero_mujeres;
      this.cardValues.men = total_comunero_hombre;
      this.hideLoader();
    } catch (err) {
      console.error(err);
      this.hideLoader();
      sweetalert.fire(
        "Error",
        "ha ocurrido un error al procesar la solicitud",
        "error"
      );
    }
  },
};
</script>
